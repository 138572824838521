body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* main page header banner */
.mainTitle{
  display: flex;
  color: white;
  background-color: black;
  top: 0%;
  margin: 0%;
  padding: 10px;
  height: 10vh;
  align-items: center; 
  justify-content: center;
}

/* Welcome page text */
#landerText{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Menu navbar buttons div*/
#projectList{
  display: flex;
  justify-content: center;
}

/* Menu navbar buttons */
#projectList button{
  display: flex;
  /* list-style: none; */
  /* text-align: center; */
  background-color: #000000;
  width: 150px;
  line-height: 60px;
  text-decoration: none;
  flex-grow: 1;
}
/* Because I put the buttons inside of links, I need this class and styling to adjust the text to center */
.pageBtn button{
  display: flex;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
}

.pageLinks{
  /* flex-grow: 1; */
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
}

.pageLinks :hover {
  text-decoration: none;
  color: #75a7aa;
  background-color: #33B5E5;
} 





/* default class for all components, remove when project name is selected */


/* About Component CSS */

.aboutMeCntr{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
/* About - profile detail CSS */
.introDetails{
  width: 40vw;
  margin: 0%;
}

/* About - contact CSS */
.contactCntr{
  display: flex;
  align-items: center;
}

.contactDetails{
  margin: 5px;
}

/* Project component Styling
/* container  */
.compCntr{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compImg{
  border: 1px solid black;
  width: 40vw;
}

.compLive{
  display: inline;
}
.compGithub{
  display: inline;
}
.compDetails{
  width: 40vw;
}